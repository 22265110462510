import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import PullToRefresh from 'react-simple-pull-to-refresh'

import {getMe} from '../redux/actions'
import Auth from './Auth'
import Install from './Install'
import Menu from './Menu'
import downPng from '../imgs/down.png'

let Main = ({}) => {
  let dispatch = useDispatch()

  let device = useSelector(state => state.device)
  let isPwa = device?.includes('PWA')
  if (process.env.NODE_ENV === 'development') isPwa = true
  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)
  //let loading = useSelector(state => state.loading)
  let [num, setNum] = useState(1)

  let handleRefresh = async () => {
    await dispatch(getMe())
  }

  //tabOut
  useEffect(() => {
    const seeU = async () => {
      const state = document.visibilityState
      if (state === 'hidden') {
        // Handle background state
      }
      if (state === 'visible') {
        setNum(num => num + 1)
        await dispatch(getMe())
      }
    }

    document.addEventListener('visibilitychange', seeU)
    return () => {
      document.removeEventListener('visibilitychange', seeU)
    }
  }, [])

  let page
  if (false) {
    page = <div />
  } else if (!user?.state) {
    page = <Auth />
  } else {
    page = (
      <div className={'flexCol'} style={{alignSelf: 'stretch', alignItems: 'center'}}>
        <Menu />
      </div>
    )
  }

  return (
    <div
      className={'mainList lato300'}
      style={{
        alignSelf: 'stretch',
        paddingTop: tab != 'home' && 80,
        flex: 1,
      }}>
      {tab == 'home' ? <Menu /> : <>{page}</>}
    </div>
  )
}
export default Main
