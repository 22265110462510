import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {DndContext} from '@dnd-kit/core'
import QuickPinchZoom from 'react-quick-pinch-zoom'

import Draggable from './Draggable'

let Map = ({arr, setArr, send}) => {
  let screenWidth = useSelector(state => state.screenWidth)
  let screenHeight = useSelector(state => state.screenHeight)
  let [zoom, setZoom] = useState(0.69)

  const [tableOffset, setTableOffset] = useState({x: 0, y: 0})
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState({x: 0, y: 0})

  const [transform, setTransform] = useState({
    scale: 1,
    offsetX: screenWidth / 2,
    offsetY: screenHeight / 2,
  })

  const onZoom = useCallback(({x, y, scale}) => {
    setZoom(scale)
  }, [])

  let handleDragEnd = e => {
    let card = arr.find(a => e.active.id == a.id)
    if (card) {
      const scale = 1 / zoom
      card.posX += e.delta.x * scale
      card.posY += e.delta.y * scale
      send(e.active.id, card.posX, card.posY)
      setArr(arr.map(a => (a.id === card.id ? card : a)))
    }
  }

  const onMouseDown = e => {
    setIsDragging(true)
    setDragStart({x: e.clientX, y: e.clientY})
  }

  const onMouseMove = e => {
    if (!isDragging) return
    const dx = e.clientX - dragStart.x
    const dy = e.clientY - dragStart.y

    setTransform(prevTransform => ({
      ...prevTransform,
      offsetX: prevTransform.offsetX + dx,
      offsetY: prevTransform.offsetY + dy,
    }))

    setDragStart({x: e.clientX, y: e.clientY})
  }

  const onMouseUp = () => {
    setIsDragging(false)
  }

  let cards = arr?.map(a => {
    return <Draggable key={a.id} id={a.id} posX={a.posX + tableOffset.x} posY={a.posY + tableOffset.y} zoom={zoom} />
  })

  return (
    <QuickPinchZoom
      onUpdate={onZoom}
      minZoom={0.2}
      maxZoom={10}
      zoomOutFactor={0.01}
      inertiaFriction={0.9}
      enabled={false}
      shouldInterceptWheel={e => {
        e.ctrlKey || e.metaKey
      }}>
      <div
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp} //off screen
        style={{...local.map, width: screenWidth, height: screenHeight}}>
        <div
          style={{
            ...local.transform,
            transform: `translate(${transform.offsetX}px, ${transform.offsetY}px) scale(${transform.scale})`,
          }}>
          <div style={{...local.vLine, left: `${tableOffset.x}px`}} />
          <div style={{...local.hLine, top: `${tableOffset.y}px`}} />
          <DndContext onDragEnd={handleDragEnd}>{cards} </DndContext>
          {true && <div></div>}
        </div>
      </div>
    </QuickPinchZoom>
  )
}

let local = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'center',
  },
  hLine: {
    height: 1,
    width: 20000,
    left: -10000,
    backgroundColor: '#555',
    position: 'absolute',
  },
  vLine: {
    height: 20000,
    top: -10000,
    width: 1,
    backgroundColor: '#555',
    position: 'absolute',
  },
  transform: {
    transformOrigin: 'center center',
    position: 'relative',
  },
  map: {
    backgroundColor: '#222',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'all-scroll',
  },
}

export default Map
