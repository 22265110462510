import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useCookies} from 'react-cookie'
import {v4 as uuidv4} from 'uuid'
import Axios from 'axios'
import key from '../../imgs/key.png'
import floppy from '../../imgs/floppy3.png'

import search2 from '../../imgs/search2.png'
import RawList from './RawList'
import Note from './Note'

let Spots = ({spot}) => {
  let [search, setSearch] = useState('')
  let [paste, setPaste] = useState(false)
  let [raw, setRaw] = useState([])
  const [targetLineNumbers, setTargetLineNumbers] = useState([1, 3]) // Start with an empty array
  const [cookies, setCookie] = useCookies(['moxTest'])
  let [uid, setUid] = useState(null)

  let [arr, setArr] = useState([])
  let [error, setError] = useState([])

  //autoSave
  useEffect(() => {
    console.log('autosave', uid, arr)
  }, [arr])

  useEffect(() => {
    temp()
  }, [])

  let temp = () => {
    // if no - moxUid cookie OR localStorage moxUid
    console.log('temp', cookies['moxUid'])

    if (!cookies.uid) {
      let moxUid = uuidv4()
      console.log('set', moxUid)

      setCookie('uid', moxUid)
      //localStorage save moxUid
    } else {
      console.log('has temp', cookies.uid)
      if (cookies.uid) setUid(cookies.uid)
      //if(localStorage moxUid) setUid( ls[moxUid] )
    }
  }

  let screenWidth = useSelector(state => state.screenWidth)
  let screenHeight = useSelector(state => state.screenHeight)

  let rawInput = text => {
    //multi paste
    if (text?.length - raw?.length > 1) {
      console.log('multi', text)
      setRaw(text)
      let addedLines = text?.length - (raw?.length - 1)
      let start = text?.length - addedLines
      let end = text?.length
      let addedArr = text?.slice(start, text?.length)
      getBatch(addedArr, start, end)
      return
    }

    //single enter
    if (raw?.length < text?.length) {
      const word = text[text.length - 2]?.children[0]?.text
      setRaw(text)
      let lineNum = text?.length - 1
      searchCard(word, lineNum)
    }

    //went back,
    if (text?.length < raw?.length) {
      let num = text?.length
      setRaw(text)
      setError(past => {
        return past?.filter(a => a != num)
      })
    }
  }

  let cleanText = text => {
    let textArr = text?.split(' ')
    let first = textArr[0]
    let firstNum = !isNaN(+first)
    let amount = 1

    if (firstNum) {
      //get amount
      //console.log('firstNum', first)
    }
    let regex = /^\d+x/
    let firstNumX = regex.test(first)
    if (firstNumX) {
      //get amount
      //console.log('firstNumX', first)
    }

    let regexP = /^\([^\)]+\)$/
    let pIndex = textArr.map((part, index) => (regexP.test(part) ? index : null)).filter(index => index !== null)

    let out = ''
    textArr?.map((a, k) => {
      if (k == 0 && (firstNum || firstNumX)) {
        return
      }

      if (pIndex?.length > 0) {
        if (k >= pIndex[0]) return
      }
      out += ` ${a}`
    })

    return out.substring(1)
  }

  let getBatch = async (batchArr, start, end) => {
    const requests = batchArr.map((item, index) => {
      return new Promise(resolve => {
        setTimeout(async () => {
          let text = item.children[0]?.text
          text = cleanText(text)
          let num = index + start + 1
          //console.log('num', num)
          try {
            const res = await searchCard(text, num)
            //console.log('batch res:', index, num, res.data)
          } catch (error) {
            //console.error('batch err:', index, error)
          }
          resolve()
        }, index * 60)
      })
    })
    for (const req of requests) {
      await req
    }
  }

  let searchCard = async (text, num) => {
    return new Promise(async resolve => {
      if (!text) return

      console.log('search', text)
      //first look for exact name / search my db
      text = text.toLowerCase()
      try {
        let have = await Axios.get(`https://moxtest.app/${text}.png`)
        if (have?.headers?.[`content-type`] == 'image/png') {
          setArr(prevArr => [...prevArr, {name: text}])
          console.log('have', text)
          resolve('ok')
          return
        }
      } catch (err) {
        console.log('have err', err, num)
      }

      //get new
      try {
        let res = await Axios.get('https://api.scryfall.com/cards/named?fuzzy=' + text)
        let img = res.data?.image_uris?.normal || false
        let name = res.data?.name?.replaceAll(' ', '_').replaceAll('/', '~')
        addCard(img, name)
      } catch (err) {
        if (!error?.includes(num)) {
          setError([...error, num])
        }
      }
      resolve('ok')
    })
  }

  let addCard = async (img, name) => {
    name = name.toLowerCase()
    console.log('rev order?', name)
    //let res = await Axios.post(`https://localhost:433/card`, {img, name})
    let res = await Axios.post(`https://moxtest.app/card`, {img, name})
    let cardName = res.data?.name
    setArr(prevArr => [...prevArr, {name: cardName}])
  }

  let save = () => {
    console.log('save')
  }

  return (
    <>
      {spot == 4 && (
        <div
          style={{
            height: 100,
            width: 200,
            zIndex: 50,
            position: 'absolute',
            top: screenHeight / 2,
            left: 0,
          }}>
          <div
            className={'flexCenter'}
            style={{height: 150, width: 100, border: '1px solid #555', color: '#888'}}>{`+`}</div>
        </div>
      )}
      {spot == 1 && (
        <>
          {/* raw */}
          {paste && (
            <div
              className={'flexCol'}
              style={{
                height: screenHeight / 2 - 2,
                width: 300,
                border: '1px solid #555',
                top: 0,
                left: screenWidth / 2 - 300,
                position: 'absolute',
                borderBottomWidth: 0,
                borderRightWidth: 0,
                color: '#626262',
                lineHeight: 0.3,
                padding: 0,
                overflow: 'hidden',
              }}>
              <Note rawInput={rawInput} error={error} />
            </div>
          )}
          {false && (
            <div
              onClick={() => save()}
              className={'flexCenter'}
              style={{
                height: 40,
                width: 40,
                position: 'absolute',
                top: screenHeight / 2 - 41,
                left: screenWidth / 2 - 341,
                border: '1px solid #555',
                cursor: 'pointer',
                zIndex: 20,
              }}>
              <img className={'floppy'} src={floppy} style={{height: 26, width: 24}} />
            </div>
          )}
          {!paste && (
            <div
              onClick={() => setPaste(true)}
              className={'flexCenter flexCol'}
              style={{
                height: screenHeight / 2,
                width: 300,
                border: '1px solid #555',
                top: 0,
                left: screenWidth / 2 - 300,
                position: 'absolute',
                borderBottomWidth: 0,
                borderRightWidth: 0,
                color: '#626262',
              }}>
              <div>{`+`}</div>
              <div>{`paste raw list`}</div>
            </div>
          )}
          {raw?.length > 1 && <RawList arr={arr} />}

          {/* login */}
          <div className={'introButton flexCenter'} style={{...local.login, top: screenHeight / 2 - 201}}>
            <img src={key} style={{height: 20, width: 20}} />
          </div>
          <div
            className={'flexCenter'}
            style={{
              height: 41,
              width: 170,
              border: '1px solid #555',
              top: screenHeight / 2 - 44,
              left: 99,
              position: 'absolute',
              borderBottomWidth: 0,
              borderLeftWidth: 0,
              color: '#555',
            }}>
            <div style={{marginRight: 6, opacity: 0.6, marginLeft: 11, marginTop: 6}}>
              <img src={search2} style={{height: 20, width: 20}} />
            </div>
            <input
              autoFocus
              className={'lato300'}
              spellCheck={false}
              style={{
                ...local.inputSearch,
                opacity: search?.length > 0 ? 1 : 0.6,
                color: search?.length > 0 ? '#888' : '#555',
              }}
              value={search}
              onChange={e => {
                setSearch(e.target.value)
              }}
              placeholder={'mox sapphire'}
            />
          </div>
          <div
            className={'introButton'}
            style={{
              height: 150,
              width: 100,
              zIndex: 50,
              position: 'absolute',
              top: screenHeight / 2 - 151,
              left: -2,
            }}>
            <div
              className={'flexCenter'}
              style={{
                height: 150,
                width: 100,
                border: '0.5 px solid #555',
                color: '#888',
                borderBottomWidth: 0,
              }}>{`+`}</div>
          </div>
        </>
      )}
      {spot == 2 && (
        <div
          style={{
            height: 100,
            width: 200,
            zIndex: 50,
            position: 'absolute',
            top: screenHeight / 2 - 151,
            left: screenWidth - 102,
          }}>
          <div
            className={'flexCenter'}
            style={{height: 150, width: 100, border: '1px solid #555', color: '#888'}}>{`+`}</div>
        </div>
      )}
      {spot == 3 && (
        <div
          style={{
            height: 100,
            width: 200,
            zIndex: 50,
            position: 'absolute',
            top: screenHeight / 2,
            left: screenWidth - 100,
          }}>
          <div
            className={'flexCenter'}
            style={{height: 150, width: 100, border: '1px solid #555', color: '#888'}}>{`+`}</div>
        </div>
      )}
    </>
  )
}

export default Spots

let local = {
  raw: {
    backgroundColor: '#222',
    border: '0px solid #555',
    width: 283,
    fontSize: 16,
    flex: 1,
    //color: '#888',
    padding: 10,
    paddingRight: 4,
  },
  inputSearch: {
    backgroundColor: '#222',
    border: '0px solid #555',
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 133,
    height: 39,
    fontSize: 18,
    flex: 1,
    marginLeft: -3,
  },
  login: {
    height: 50,
    width: 50,
    border: '1px solid #555',
    left: -2,
    position: 'absolute',
    borderBottomWidth: 0,
  },
}
