import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

let Begin = ({wid, make, room}) => {
  let [fade, setFade] = useState(0)
  let [joinId, setJoinId] = useState('')
  let [showJoin, setShowJoin] = useState(false)

  console.log('begin')

  useEffect(() => {
    if (wid) {
      const timeoutId = setTimeout(() => setFade(1), 10)
      return () => clearTimeout(timeoutId)
    }
  }, [wid])

  let screenWidth = useSelector(state => state.screenWidth)
  let screenHeight = useSelector(state => state.screenHeight)

  return (
    <div style={{transition: 'opacity 600ms ease-in-out', opacity: fade}}>
      <div
        onClick={() => make()}
        className={'introButton'}
        style={{
          ...local.create,
          top: screenHeight / 2 - 51,
          left: screenWidth / 2,
        }}>
        {`create +`}
      </div>
      {showJoin ? (
        <div
          className={'flexRow'}
          style={{
            position: 'absolute',
            top: screenHeight / 2,
            left: screenWidth / 2,
          }}>
          <div className={'introButtonText'} style={local.joinBox}>
            <div style={{marginRight: 4, opacity: 0.5, marginLeft: -2}}>{`#`}</div>
            <input
              autoFocus
              className={'lato300'}
              type="number"
              inputMode="numeric"
              style={local.inputText}
              value={joinId}
              onChange={e => {
                setJoinId(e.target.value)
              }}
              placeholder={'000000'}
            />
          </div>
          {joinId?.length >= 6 && (
            <div onClick={() => join()} className={'introButton flexCenter'} style={local.joinSubmit}>
              {`+`}
            </div>
          )}
        </div>
      ) : (
        <div
          onClick={() => setShowJoin(true)}
          className={'introButton'}
          style={{
            ...local.join,
            top: screenHeight / 2,
            left: screenWidth / 2,
          }}>{`join`}</div>
      )}
    </div>
  )
}

export default Begin

let local = {
  inputText: {
    backgroundColor: '#222',
    border: '0px solid #555',
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 90,
    height: 48,
    fontSize: 18,
    flex: 1,
    marginLeft: -3,
    color: '#888',
  },
  inputSearch: {
    backgroundColor: '#222',
    border: '0px solid #555',
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 133,
    height: 39,
    fontSize: 18,
    flex: 1,
    marginLeft: -3,
  },
  join: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    width: 100,
    zIndex: 50,
    position: 'absolute',
    color: '#888',
    paddingLeft: 10,
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  joinBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    width: 100,
    zIndex: 50,
    color: '#888',
    paddingLeft: 10,
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  joinSubmit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    width: 50,
    zIndex: 50,
    color: '#888',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    fontSize: 20,
  },
  create: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    width: 100,
    zIndex: 50,
    position: 'absolute',
    color: '#888',
    paddingLeft: 10,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
  },
  login: {
    height: 50,
    width: 50,
    border: '1px solid #555',
    left: -2,
    position: 'absolute',
    borderBottomWidth: 0,
  },
}
