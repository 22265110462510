import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

let RawList = ({arr}) => {
  let screenWidth = useSelector(state => state.screenWidth)
  let screenHeight = useSelector(state => state.screenHeight)

  let showCards = arr?.map((a, k) => {
    let marginTop = -143
    if (k == 0) marginTop = 0
    //let src = `https://localhost:433/${a.name}.png`
    let src = `https://moxtest.app/${a.name}.png`
    return (
      <div key={k} className={'card'}>
        <img style={{width: 115, height: 160.7, borderRadius: 6}} src={src} />
      </div>
    )
  })

  return (
    <div
      className={'flexCol rawList'}
      style={{
        height: screenHeight / 2,
        top: 0,
        left: screenWidth / 2 - 417,
        position: 'absolute',
        borderBottomWidth: 0,
        borderRightWidth: 0,
        color: '#626262',
        overflow: 'scroll',
      }}>
      {showCards}
    </div>
  )
}

export default RawList

let local = {}
