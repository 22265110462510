import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {w3cwebsocket as W3CWebSocket} from 'websocket'

import Menu from './Menu'

import Map from './Map'

function os(object) {
  return JSON.stringify(object)
}

let Flat = ({}) => {
  let screenWidth = useSelector(state => state.screenWidth)
  let screenHeight = useSelector(state => state.screenHeight)
  let [zoom, setZoom] = useState(1.0)
  let [spot, takeSpot] = useState(null)

  let [from, setFrom] = useState(null)
  let [to, setTo] = useState(null)
  let [lobby, setLobby] = useState([])
  let lobbyRef = useRef([])

  let [wid, setWid] = useState('')
  let [room, setRoom] = useState(null)
  let [on, setOn] = useState(false)
  let [nextId, setNextId] = useState(1)
  let ws = useRef(null)

  let [arr, setArr] = useState([])

  //
  let [go, setGo] = useState(false)
  let goRef = useRef(go)
  //

  useEffect(() => {
    ws.current = new W3CWebSocket('wss://moxtest.app:7070') //('ws://localhost:7072') //
    initWs()
  }, [])

  let make = () => {
    ws.current.send(os({action: 'make', wid, name: 'jose', stats: {}}))
  }

  let start = () => {
    ws.current.send(os({action: 'start', roomId: room, stats: {}}))
  }

  let join = joinId => {
    if (joinId?.length != 6) return
    ws.current.send(os({action: 'join', roomId: joinId, wid, name: 'jj', stats: {}}))
  }

  let send = () => {
    ws.current.send(os({action: 'move', roomId: room, arr, stats: {}}))
    setArr(arr)
  }

  let initWs = () => {
    ws.current.onmessage = message => {
      let obj = JSON.parse(message.data)

      if (obj.message == 'welcome') {
        console.log('welcome', obj.id)
        setWid(obj.id)
      }

      if (obj.message == 'lobby') {
        console.log('lobby', obj.roomId, obj.players)
        setRoom(obj.roomId)
        setLobby(obj.players)
        lobbyRef.current = obj.players
      }

      if (obj.message == 'on') {
        console.log('on')
        setArr(obj.arr)
        setOn(true)
      }

      if (obj.message == 'move') {
        console.log('move in', obj.arr)
        setArr(obj.arr)
        //setGo(true)
      }
    }
  }

  return (
    <div className={'flexCol'} style={{width: screenWidth, height: screenHeight, backgroundColor: '#222'}}>
      <Map arr={arr} setArr={setArr} send={send} />
      <Menu
        wid={wid}
        on={on}
        room={room}
        join={join}
        make={make}
        lobby={lobby}
        start={start}
        spot={spot}
        takeSpot={takeSpot}
      />
    </div>
  )
}

let styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'center',
    marginBottom: 80,
  },
}

export default Flat
