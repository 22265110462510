import React, {useState} from 'react'

import {useDraggable} from '@dnd-kit/core'
import {CSS} from '@dnd-kit/utilities'
import Card from './Card'

let Draggable = ({id, posX, posY, zoom}) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id,
  })
  const stopPropagation = e => {
    e.stopPropagation()
  }

  const style = {
    transform: CSS.Translate.toString(transform),
  }

  return (
    <button
      ref={setNodeRef}
      style={{...style, position: 'absolute', left: posX * zoom, top: posY * zoom}}
      {...listeners}
      {...attributes}
      onMouseDown={stopPropagation}>
      <Card zoom={zoom} />
    </button>
  )
}

export default Draggable
