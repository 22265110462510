import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import SidebarX from './SidebarX'
import skull from '../imgs/skull.png'

let Menu = () => {
  let dispatch = useDispatch()
  let isMobile = useSelector(state => state.isMobile)
  let screenWidth = useSelector(state => state.screenWidth)
  let showSideBar = useSelector(state => state.showSideBar)
  let wallet = useSelector(state => state.wallet)
  let tab = useSelector(state => state.tab)
  let walletSol = useSelector(state => state.walletSol)
  let frontHalf = wallet?.obj?.address?.substring(0, 7) || null
  let frontHalfSol = walletSol?.obj?.publicKey?.toString()?.substring(0, 7) || null

  if (tab == 'home') return null

  let refresh = () => {
    window.location.reload()
  }

  let walletClick = () => {
    console.log('wallet?.obj?.address', wallet?.obj?.address)
    console.log('walletSol?.obj?.publicKey?.toString()', walletSol?.obj?.publicKey?.toString())
  }

  console.log('tab', tab)

  return (
    <div className={'top'} style={{width: screenWidth}}>
      <div className={'topMid'} style={{width: isMobile ? 320 : 640}}>
        <div
          onClick={() => refresh()}
          className={'flexRow lato300'}
          style={{alignItems: 'center', marginBottom: isMobile ? 7 : 0}}>
          <div style={{height: 30, width: 30, marginLeft: -4}}>
            <img src={skull} style={{height: 30, widht: 30}} />
          </div>
          <div style={{marginLeft: 4, marginBottom: 2}}>moxtest</div>
        </div>
        <div className={'flexRow'}>
          {frontHalfSol && (
            <div onClick={() => walletClick()} style={styles.walletBox}>
              {frontHalfSol}
            </div>
          )}
          {frontHalf && (
            <div onClick={() => walletClick()} style={styles.walletBox}>
              {frontHalf}
            </div>
          )}
          <div style={{marginBottom: isMobile ? 10 : 4, marginLeft: 6, marginRight: -6}}>
            <SidebarX showSideBar={showSideBar} toggleSidebar={() => dispatch({type: 'toggleSidebar'})} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Menu

let styles = {
  page: {
    flex: 1,
    width: 330,
    alignSelf: 'center',
  },
  walletBox: {
    fontSize: 14,
    marginRight: 6,
    border: '1px solid #aaa',
    borderRadius: 4,
    padding: 3,
    paddingLeft: 5,
    paddingRight: 5,
    height: 18,
    color: '#555',
    marginTop: -1.5,
  },
}
