import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

let Center = ({spot, takeSpot, room}) => {
  let screenWidth = useSelector(state => state.screenWidth)
  let screenHeight = useSelector(state => state.screenHeight)

  return (
    <div
      style={{
        height: 100,
        width: 200,
        zIndex: 50,
        position: 'absolute',
        top: screenHeight / 2 - 51,
        left: screenWidth / 2 - 50,
      }}>
      {/*center top */}
      <div className={'flexRow'} style={{alignItems: 'center'}}>
        {spot == 1 ? (
          <div
            className={' flexCenter'}
            style={{
              color: '#888',
              borderBottomWidth: 0,
              borderRightWidth: 0,
              height: 50,
              width: 51,
            }}>
            {``}
          </div>
        ) : (
          <div
            onClick={() => takeSpot(1)}
            className={'introButton flexCenter'}
            style={{
              color: '#888',
              borderBottomWidth: 0,
              borderRightWidth: 0,
              height: 50,
              width: 50,
            }}>
            {`+`}
          </div>
        )}
        {spot == 2 ? (
          <div
            className={' flexCenter'}
            style={{
              color: '#888',
              borderBottomWidth: 0,
              borderLeftWidth: 0,
              height: 50,
              width: 51,
            }}>
            {``}
          </div>
        ) : (
          <div
            onClick={() => takeSpot(2)}
            className={'introButton flexCenter'}
            style={{
              color: '#888',
              borderLeftWidth: 0,
              borderBottomWidth: 0,
              height: 50,
              width: 50,
            }}>
            {`+`}
          </div>
        )}
        <div style={{color: '#888', marginLeft: 10, opacity: 0.8}}>
          <span style={{opacity: 0.4}}>{`#`}</span>
          {`${room}`}
        </div>
      </div>
      {/*center bot */}
      <div className={'flexRow'}>
        {spot == 4 ? (
          <div
            className={' flexCenter'}
            style={{
              color: '#888',
              borderTopWidth: 0,
              borderRightWidth: 0,
              height: 50,
              width: 51,
            }}>
            {``}
          </div>
        ) : (
          <div
            onClick={() => takeSpot(4)}
            className={'introButton flexCenter'}
            style={{
              color: '#888',
              borderTopWidth: 0,
              borderRightWidth: 0,
              height: 50,
              width: 50,
            }}>
            {`+`}
          </div>
        )}
        {spot == 3 ? (
          <div
            className={' flexCenter'}
            style={{
              color: '#888',
              borderTopWidth: 0,
              borderRightWidth: 0,
              height: 50,
              width: 51,
            }}>
            {``}
          </div>
        ) : (
          <div
            onClick={() => takeSpot(3)}
            className={'introButton flexCenter'}
            style={{
              color: '#888',
              borderTopWidth: 0,
              borderLeftWidth: 0,
              height: 50,
              width: 50,
            }}>
            {`+`}
          </div>
        )}
      </div>
    </div>
  )
}

export default Center

let local = {}
