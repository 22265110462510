import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import gbb from '../../cards/gbb.jpg'

let Card = ({zoom}) => {
  return (
    <div style={{borderRadius: 4, overflow: 'hidden'}}>
      <img src={gbb} style={{height: 160.17 * zoom, width: 115 * zoom}} />
    </div>
  )
}

let styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'center',
    marginBottom: 80,
  },
}

export default Card
