import React, {useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {createEditor} from 'slate'
import {Slate, Editable, withReact, ReactEditor} from 'slate-react'

const CodeElement = props => {
  return (
    <pre {...props.attributes}>
      <code>{props.children}</code>
    </pre>
  )
}

let Note = ({rawInput, error}) => {
  let screenHeight = useSelector(state => state.screenHeight)

  const [value, setValue] = useState([
    {
      type: 'paragraph',
      children: [{text: 'A line of text in a paragraph.'}],
    },
  ])

  const initialValue = [
    {
      type: 'paragraph',
      children: [{text: ''}],
    },
  ]

  const DefaultElement = ({children, attributes, error, index}) => {
    //let text = children[0]?.props?.text?.text

    let boop = {}
    if (error.includes(index + 1)) {
      boop = {color: '#ad1049', textDecoration: 'line-through', opacity: 0.9}
    }
    return (
      <div {...attributes} style={boop}>
        {children}
      </div>
    )
  }

  const [editor] = useState(() => withReact(createEditor()))
  const renderElement = useCallback(
    props => {
      const path = ReactEditor.findPath(editor, props.element)
      const index = path[0]
      switch (props.element.type) {
        case 'code':
          return <CodeElement {...props} />
        default:
          return <DefaultElement {...props} error={error} index={index} />
      }
    },
    [error],
  )

  return (
    <Slate editor={editor} initialValue={initialValue} onChange={ok => rawInput(ok)}>
      <Editable
        className={'lato300 raw plate'}
        autoFocus
        spellCheck={false}
        renderElement={renderElement}
        onKeyDown={event => {
          if (event.key === '&') {
            event.preventDefault()
            editor.insertText('and')
          }
        }}
        style={{
          ...local.raw,
          height: screenHeight / 2 - 2,
        }}
      />
    </Slate>
  )
}

export default Note

let local = {
  raw: {
    backgroundColor: '#222',
    border: '0px solid #555',
    width: 283,
    fontSize: 16,
    flex: 1,
    //color: '#888',
    padding: 10,
    paddingRight: 4,
  },
}
