import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Center from './Center'
import Begin from './Begin'
import Spots from './Spots'

let Menu = ({wid, on, room, join, make, lobby, start, spot, takeSpot}) => {
  let screenWidth = useSelector(state => state.screenWidth)
  let screenHeight = useSelector(state => state.screenHeight)

  let showLobby = lobby?.map(a => {
    /*
    return (
      <div
        key={a.wid}
        className={'flexCenter'}
        style={{
          height: 46,
          width: 46,
          border: '1px solid #888',
          borderRightWidth: 0,
          borderBottomWidth: 0,
          overflow: 'hidden',
        }}>
        {`${a.name}`}
      </div>
    )
    */
  })

  console.log('menu room', wid, room)

  return (
    <div>
      {/* buttons */}
      {!on && wid && (
        <>
          {/* start */}
          {!room && <Begin wid={wid} make={make} />}
          {/* room */}
          {room && (
            <>
              <Spots spot={spot} />
              <Center room={room} spot={spot} takeSpot={takeSpot} />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Menu

/*
{room && (
  <div className={'flexRow'} style={{position: 'absolute', bottom: 0, right: 0, alignItems: 'center'}}>
    <div style={{marginRight: 10, fontSize: 22, opacity: 0.7, color: '#fff'}}>{`#${room}`}</div>
    <div onClick={() => start()} style={{height: 47, width: 47, backgroundColor: '#555'}} />
  </div>
)}
{lobby && (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column-reverse',
      position: 'absolute',
      bottom: 47,
      right: 0,
      color: '#fff',
    }}>
    {showLobby}
  </div>
)}
*/

let local = {}
